import React, {useMemo} from 'react';
import {classes, style, vars} from './doppe-standard-action-button-template.st.css';
import {doppeActionButtonTemplateDefine, DoppeActionView} from '../../../doppe-sdk';
import {DoppeViewerActionView} from '../../doppe-viewer-action-view/doppe-viewer-action-view';
import {
    Button,
    Icon,
    ImageViewPositionHorizontal,
    ImageViewPositionVertical,
    ImageViewResizeMode
} from '@wix/devzai-utils-react';
import IconChevronDown from './icons/ChevronDown.svg';
import IconLock from './icons/Lock.svg';
import {FlexLayout} from '@wix/devzai-common-client';
import {
    doppeHideableValueIsVisible,
    doppeHideableValueIsVisibleAndNotEqualValue
} from '../../../client-server-common/types/doppe-hideable-value';
import {
    DopeStandardActionButtonColorMode,
    DoppeStandardActionButtonTemplate,
    DoppeStandardActionButtonTemplateMetadata
} from './doppe-standard-action-button-template.metadata'
import {DoppePageMediaView, DoppePageMediaViewMediaBackground} from '../../doppe-page-media-view/doppe-page-media-view';
import {
    BymoPageStyleScope,
    BymoPageStyleScopeColors,
    useBymoPageScopeColor
} from '../../bymo-page-style/bymo-page-style';
import {
    colorBlend,
    colorDarken,
    colorGetAnalogousColor,
    colorIsDark,
    colorResolveLegibleForegroundColor,
    colorResolveMiddleColor,
    colorRotateHue,
    colorToRgb,
    colorWhiten,
    cssResolveRgbColorComponentsForFunctionalNotationFormat,
    evaluateFunction,
    HtmlObjectHorizontalPosition,
    HtmlObjectResizeMode,
    HtmlObjectVerticalPosition,
    materialPrimaryColorPaletteResolvePrimaryColor,
    materialPrimaryColorPaletteResolvePrimaryRelativeColor
} from '@wix/devzai-utils-common';
import {useBymoPageProps} from '../../../client-server-common';
import {DoppeDtoActionViewType} from '../../../client-server-common/types/doppe-dto-action';
import {DoppeImageBannerDefaultImageResource} from '../../../doppe-static-assets/doppe-static-assets';

const DoppeStandardActionButton = React.memo(function DoppeStandardActionButton(props: DoppeActionView.Props<DoppeStandardActionButtonTemplate.TemplateProps>) {
    const {
        templateProps: {
            roundness,
            skin,
            hoverAnimation,
            colorMode
        },
        enableAnimation = false,
        viewType,
        actionIndexInList,
        isLockedObservable,

        ...actionViewProps
    } = props;

    const bymoPageProps = useBymoPageProps();

    const baseColor = useBymoPageScopeColor(BymoPageStyleScopeColors.MainColor, BymoPageStyleScope.PageContent);

    const mainColor = evaluateFunction(() => {

        if (actionIndexInList === null) {
            return baseColor;
        }

        const baseColorRgb = colorToRgb(baseColor);
        if (baseColorRgb === null) {
            return baseColor;
        }

        switch (colorMode) {
            case DopeStandardActionButtonColorMode.Unified: {
                return baseColor;
            }
            case DopeStandardActionButtonColorMode.Alternate: {

                if (actionIndexInList % 2 === 1) {
                    return colorIsDark(baseColorRgb) ? colorWhiten(baseColorRgb, 0.8) : colorDarken(baseColorRgb, 0.85);
                }

                return baseColor;
            }
            case DopeStandardActionButtonColorMode.Analogous: {

                if (actionIndexInList % 2 === 1) {
                    return colorGetAnalogousColor(baseColor) ?? baseColor;
                }

                return baseColor;
            }
            case DopeStandardActionButtonColorMode.Triad: {
                return colorRotateHue(baseColorRgb, 120 * actionIndexInList);
            }
        }
    })

    const mainColorRgb = useMemo(() => cssResolveRgbColorComponentsForFunctionalNotationFormat(mainColor), [mainColor]);

    const blendedMainColor = useMemo(() => {
        return colorIsDark(mainColor) ? colorBlend('#fff', mainColor, 0.2) : colorBlend('#000', mainColor, 0.1)
    }, [mainColor])

    const materialPrimaryColorPalette = bymoPageProps.materialPrimaryColorPalette;
    const gradientSkinColors = useMemo(() => {

        if (materialPrimaryColorPalette) {

            const gradientStart = materialPrimaryColorPaletteResolvePrimaryColor(materialPrimaryColorPalette);
            const gradientEnd = materialPrimaryColorPaletteResolvePrimaryRelativeColor(materialPrimaryColorPalette, 4);

            const middleColor = colorResolveMiddleColor(gradientStart, gradientEnd);

            return {
                gradientStart: gradientStart,
                gradientEnd: gradientEnd,
                gradientLegibleForeground: middleColor ? colorResolveLegibleForegroundColor(middleColor) : null
            }

        } else {
            return null
        }
    }, [materialPrimaryColorPalette]);

    return (
        <DoppeViewerActionView
            className={style(classes.root, {
                enableAnimation: enableAnimation,
                skin: skin,
                hoverAnimation: hoverAnimation,
                viewType: evaluateFunction(() => {
                    switch (viewType) {
                        case DoppeDtoActionViewType.InPageFrameless: return 'strip';
                        case DoppeDtoActionViewType.ActionButton: return 'button';
                        case DoppeDtoActionViewType.InPage: return 'card';
                        case DoppeDtoActionViewType.BannerButton: return 'banner';
                    }
                })
            })}
            actionWidgetContainerClassName={classes.actionWidgetContainer}
            actionButtonContainerClassName={classes.actionButtonContainer}
            actionWidgetRoundness={roundness}
            actionIndexInList={actionIndexInList}
            renderActionButton={(renderProps) => {

                const {
                    actionButtonComponentProps: {
                        className,
                        href,
                        ...htmlAttributes
                    },
                    actionTitle,
                    actionDescription,
                    actionImage,
                    actionViewType,
                    bannerMedia,
                    isLocked,

                    isWidgetOpener
                } = renderProps;

                const hasImage = doppeHideableValueIsVisibleAndNotEqualValue(actionImage, null) && actionImage !== null;

                return (
                    <Button
                        {...htmlAttributes}
                        className={style(
                            classes.actionButton,
                            {
                                hasImage: hasImage,
                                hasCustomContent: actionViewType === DoppeDtoActionViewType.BannerButton
                            },
                            className
                        )}
                        tagName={!isLocked && href !== undefined ? 'a' : 'button'}
                        href={isLocked ? undefined : href}
                    >
                        {evaluateFunction(() => {
                            switch (actionViewType) {
                                case DoppeDtoActionViewType.BannerButton: {
                                    return (
                                        <DoppePageMediaView
                                            className={style(classes.bannerMediaView, className)}
                                            mediaResource={bannerMedia ?? DoppeImageBannerDefaultImageResource}
                                            layoutSpec={{autoHeight: true, maxHeight: 860}}
                                            widthSpec={{contentRelativeWidth: 1}}
                                            mediaResizeMode={HtmlObjectResizeMode.Cover}
                                            mediaVerticalPosition={HtmlObjectVerticalPosition.Center}
                                            mediaHorizontalPosition={HtmlObjectHorizontalPosition.Center}
                                            videoMuted={true}
                                            autoPlayVideo={true}
                                            playVideoInLoop={true}
                                        />
                                    )
                                }
                                default: {
                                    return (
                                        <>
                                            {hasImage ? (
                                                <DoppePageMediaView
                                                    className={style(classes.actionImageView, {})}
                                                    mediaResource={actionImage}
                                                    layoutSpec={{widthHeightRatio: 1}}
                                                    widthSpec={{width: 44}}
                                                    mediaVerticalPosition={ImageViewPositionVertical.Center}
                                                    mediaHorizontalPosition={ImageViewPositionHorizontal.Center}
                                                    mediaResizeMode={ImageViewResizeMode.Cover}
                                                    mediaBackground={DoppePageMediaViewMediaBackground.Transparent}
                                                />
                                            ) : null}
                                            <div
                                                className={style(classes.actionTextContent, {}, FlexLayout.column.default, FlexLayout.fillRemainingHorizontalSpace)}
                                            >
                                                <div className={style(classes.actionTitle, {})}>{actionTitle}</div>
                                                {
                                                    doppeHideableValueIsVisible(actionDescription) ?
                                                        <div className={style(classes.actionDescription, {})}>{actionDescription}</div>
                                                        :
                                                        null
                                                }

                                            </div>
                                            {evaluateFunction(() => {

                                                if (isLocked) {
                                                    return (
                                                        <Icon
                                                            className={style(classes.lockIcon, {})}
                                                            icon={IconLock}
                                                        />
                                                    )
                                                }
                                                else if (isWidgetOpener) {
                                                    return (
                                                        <Icon
                                                            className={style(classes.chevronIcon, {})}
                                                            icon={IconChevronDown}
                                                        />
                                                    )
                                                }

                                                return null;

                                            })
                                            }
                                        </>
                                    )
                                }
                            }
                        })}

                    </Button>
                )

            }}
            style={{
                [vars.roundness]: `${roundness}px`,
                [vars.blendedMainColor]: blendedMainColor ?? undefined,
                [vars.buttonColor]: mainColor,
                [vars.mainColorRgb]: mainColorRgb ?? undefined,
                [vars.gradientStart]: gradientSkinColors?.gradientStart ?? undefined,
                [vars.gradientEnd]: gradientSkinColors?.gradientEnd ?? undefined,
                [vars.gradientLegibleForeground]: gradientSkinColors?.gradientLegibleForeground ?? undefined,
            }}
            viewType={viewType}
            isLockedObservable={isLockedObservable}
            {...actionViewProps}
        />
    )
});

export const DoppeStandardActionButtonTemplateData = doppeActionButtonTemplateDefine(
    DoppeStandardActionButtonTemplateMetadata,
    DoppeStandardActionButton)