import {arrayRemoveNullValues, AutoValue, autoValueCreate, Values} from '@wix/devzai-utils-common';
import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from '../../client-server-common/types/doppe-hideable-value';
import {DoppeDtoVideo} from '../../client-server-common/types/doppe-dto-video';
import {
    DoppeBymoPageVideoSource,
    doppeBymoPageVideoSourceResolveVideoUrl,
    DoppeExternalVideoProvider,
    doppeExternalVideoProviderResolveBusinessEntityReference
} from '../../client-server-common';
import {WixMediaResource} from '@wix/devzai-common-wix';
import {RichTextContent} from '@wix/devzai-utils-react';

export namespace DoppeActionTypeVideo {

    export const DoppeActionTypeVideoURLAutoSource = {
        SameAsVideoURL: 'SameAsVideoURL',
    } as const;

    export type DoppeActionTypeVideoURLAutoSource = Values<typeof DoppeActionTypeVideoURLAutoSource>;
    export type DoppeActionTypeVideoURLSource = AutoValue<DoppeActionTypeVideoURLAutoSource> | string;

    export interface ActionSettings {
        externalVideoUrl: DoppeBymoPageVideoSource;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        videoButtonText: DoppeHideableValue<string>;
        videoPlayerSettings: {
            autoplay: boolean;
            muteVideo: boolean;
        },
        videoImage: WixMediaResource | null;
        videoProvider: DoppeExternalVideoProvider | null,
        videoProviderId: string | null,
        videoButtonURL: DoppeActionTypeVideoURLSource;
    }
}

export const doppeActionTypeVideo = doppeActionTypeDefineMetadata<DoppeActionTypeVideo.ActionSettings>({
    id: 'io.bymo.action/video',
    resolveActionSettings: action => {
        return {
            ...doppeActionTypeVideoGetDefaultSettings(),
            ...action.settings
        }
    },
    resolveUsedBusinessEntities: actionSettings => {
        return arrayRemoveNullValues([
            actionSettings.videoProvider !== null && actionSettings.videoProviderId !== null ?
                doppeExternalVideoProviderResolveBusinessEntityReference(
                    actionSettings.videoProvider,
                    actionSettings.videoProviderId
                ) :
                null
        ])
    },
    resolveMainLink: actionSettings => {
        return doppeBymoPageVideoSourceResolveVideoUrl(actionSettings.externalVideoUrl);
    },
    isWidgetOpener: true,
    isLinkOpener: true,
    canRenderInPage: true,
})

export function doppeActionTypeVideoResolveSettingsFromBoundEntity(
    actionSettings: DoppeActionTypeVideo.ActionSettings,
    _doppeDtoCoupon: DoppeDtoVideo
): DoppeActionTypeVideo.ActionSettings {
    return actionSettings
}

export function doppeActionTypeVideoGetDefaultSettings(): DoppeActionTypeVideo.ActionSettings {
    return {
        externalVideoUrl: '',
        videoPlayerSettings: {
            autoplay: true,
            muteVideo: false
        },
        title: doppeHideableValueCreateHidden('Video Title'),
        description: doppeHideableValueCreateHidden('Video Description'),
        videoButtonText: doppeHideableValueCreateHidden('Watch on YouTube'),
        videoImage: null,
        videoProvider: null,
        videoProviderId: null,
        videoButtonURL: autoValueCreate(DoppeActionTypeVideo.DoppeActionTypeVideoURLAutoSource.SameAsVideoURL),
    }
}